<script setup>
import { computed, onMounted, ref } from 'vue'
import { SelectorIcon } from '@heroicons/vue/solid'
import { useAuthStore } from '@/Stores/Auth'
import { useDynamicModalStore } from '@/Stores/DynamicModal'
import { useNotificationsStore } from '@/Stores/Notifications'
import { useActiveTrackingReferenceStore } from '@/Stores/ActiveTrackingReference'
import { useForm } from '@/Composables/Form'
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/solid'
import Label from '@/Components/FormElements/Label.vue'
import Button from '@/Components/FormElements/Button.vue'
import InputError from '@/Components/FormElements/InputError.vue'
import ModalPanel from '@/Components/Generic/Modals/ModalPanel.vue'
import ModalTitle from '@/Components/Generic/Modals/ModalTitle.vue'


// Stores
const auth = useAuthStore()
const modal = useDynamicModalStore()
const activeTracking = useActiveTrackingReferenceStore()
const notifications = useNotificationsStore()

const form = useForm({
    reference: '',
})

// Refs
const trackingIdentifiers = ref([])

const selected = ref(null)

const trackingReferenceFormat = ref(null)

const trackingReferenceRegex = ref(null)

const query = ref('')

// Computed properties
const options = computed(() => {
    let items = []
    let foundPerfectMatch = false

    // Make the create new option first if the input string doesn't match an existing identifier
    if (query.value.length){
        trackingIdentifiers.value.forEach(identifier => {
            if (identifier.toLowerCase() === query.value.toLowerCase()) {
                items.push({label: query.value, value: query.value})
                foundPerfectMatch = true
            } else {
                if (identifier.toLowerCase().includes(query.value.toLowerCase())) {
                    items.push({label: identifier, value: identifier})
                }
            }
        })
        if (!foundPerfectMatch || items.length === 0) {
            items.unshift({label: `Create new: ${query.value}`, value: query.value})
        }

    } else {
        trackingIdentifiers.value.forEach(identifier => {
            items.push({label: identifier, value: identifier})
        })
    }

    return items
})

const inputDisplayValue = computed(() => {
    return selected.value ? selected.value.value : ''
})

// Lifecycle Hooks
onMounted(() => {
    if (activeTracking.hasReference) {
        selected.value = { label: activeTracking.reference, value: activeTracking.reference }
    }

    auth.getUser()
        .then(() => {
            let trackingModule = auth.getModule('tracking')

            trackingReferenceFormat.value = trackingModule?.configuration?.tracking_id_format
            trackingReferenceRegex.value = trackingModule?.configuration?.regex_pattern
        })
        .catch(error => {
            console.error("Error fetching user data:", error)
        })

    getTrackingReferences()
})

function onUpdateInput(event) {
    query.value = event.target.value
}

function onClick(item) {
    query.value = item.value
}

function getTrackingReferences() {
    axios.get('/client_references')
        .then(response => {
            trackingIdentifiers.value = response.data
        })
        .catch(error => {
            console.error("Error fetching tracking references:", error)
            notifications.notify({type: 'error', title: 'Error fetching tracking references'})
        })
}

function submit() {
    form.reference = selected.value?.value

    form.put('/active_client_reference', {
        onSuccess: () => {
            modal.close()
            notifications.notify({type: 'success', title: 'Tracking reference updated successfully'})
            activeTracking.fetchActiveTrackingReference()
        },
        onError: (error) => {
            console.error("Error updating tracking reference:", error)
            notifications.notify({type: 'error', title: 'Failed to update tracking reference'})
        }
    })
}
</script>

<template>
    <ModalPanel :hide-close-button="auth.trackingRequired && !activeTracking.hasReference" @close="modal.close()">
        <form @submit.prevent="submit">
            <div class="space-y-6">
                <ModalTitle>Update Tracking Reference</ModalTitle>

                <div>
                    <div class="flex items-center justify-between">
                        <Label for="tracking_id" value="Tracking Reference" :optional="!auth.trackingRequired"/>
                        <div v-if="trackingReferenceFormat" class="text-sm">
                            Format:
                            <span class="text-blue-600 dark:text-blue-400 ml-1">{{ trackingReferenceFormat }}</span>
                        </div>
                    </div>

                    <Combobox v-slot="{ activeOption }" v-model="selected" as="div" :items="options" nullable>
                        <div class="relative">
                            <ComboboxInput
                                    id="tracking_id"
                                    class="w-full relative rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 border border-slate-300 dark:border-slate-500 dark:bg-slate-900 sm:text-sm"
                                    autocomplete="off"
                                    :display-value="() => inputDisplayValue"
                                    @change="onUpdateInput"
                            />
                            <ComboboxButton
                                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <SelectorIcon class="h-5 w-5 text-slate-400" aria-hidden="true"/>
                            </ComboboxButton>
                            <transition enter-active-class="transition duration-100 ease-out"
                                        enter-from-class="transform scale-95 opacity-0"
                                        enter-to-class="transform scale-100 opacity-100"
                                        leave-active-class="transition duration-75 ease-out"
                                        leave-from-class="transform scale-100 opacity-100"
                                        leave-to-class="transform scale-95 opacity-0">
                                <ComboboxOptions
                                        class="fixed z-10 mt-1 max-h-60 w-[528px] overflow-auto rounded-md bg-white dark:bg-slate-700 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ComboboxOption v-for="item in options" v-slot="{ active }" :value="item"
                                                    @click="onClick(item)">
                                        <li :class="[ 'relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-blue-600 text-white' : 'text-slate-900 dark:text-slate-200' ]">
                                            <span style="flex-grow: 1; word-wrap: break-word;">{{ item.label }}</span>
                                        </li>
                                    </ComboboxOption>
                                </ComboboxOptions>
                            </transition>
                        </div>
                    </Combobox>

                    <InputError v-if="form.errors.reference" :message="form.errors.reference"/>
                </div>

                <div class="flex items-center justify-end space-x-2 space-y-8 relative bottom-0 w-full">
                    <Button :disabled="form.processing">Save</Button>
                </div>
            </div>
        </form>
    </ModalPanel>
</template>
