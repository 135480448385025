import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useAuthStore } from "@/Stores/Auth";
import { useOpenAnalysis } from "@/Composables/Analysis/OpenAnalysis";

/**
 * For users workspaces
 */
export const useWorkspacesStore = defineStore('workspaces', () => {

    const openAnalysis = useOpenAnalysis();

    /**
     * Array of workspaces that the current user has access to
     */
    const workspaces = ref(null)

    /**
     * The current active workspace
     */
    const activeWorkspace = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref()

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(workspaces.value == null)
    })

    const auth = useAuthStore()

    /**
     * Make sure that we define the current users access to each workspace.
     */
    const addUserAccess = (workspaces, isShare) => {

        if (typeof isShare === 'undefined') {
            isShare = false;
        }

        // If this is a share we can force read only access.
        if (isShare) {

            for (const workspace of workspaces) {
                workspace.accessExplain = {};
                workspace.accessExplain.type = 'user';
                workspace.accessExplain.user = 'Share User';
                workspace.accessExplain.permission = 'Read';
            }

            return workspaces;
        }

        for (const workspace of workspaces) {

            // Is the current user the "owner" of the workspace
            if (workspace.owner.id === auth.user.id) {

                workspace.accessExplain = {};
                workspace.accessExplain.type = 'owner';
                workspace.accessExplain.user = workspace.owner;
                workspace.accessExplain.permission = 'Admin';

            } else {

                // Check if this user has a direct user share, as this takes priority
                let shared_user = workspace.users.find(shared_user => shared_user.id === auth.user.id);

                if (typeof shared_user !== 'undefined') {

                    workspace.accessExplain = {};
                    workspace.accessExplain.type = 'user';
                    workspace.accessExplain.user = shared_user;
                    workspace.accessExplain.permission = shared_user.permissions[0];
                    continue;

                }

                // Check if this user is in a shared group(s)
                for (let shared_group of workspace.groups) {

                    // Match the use in this shared group
                    let matchedUser = shared_group.users.find(user => user.id === auth.user.id);

                    // The user is in this group
                    if (typeof matchedUser !== 'undefined') {

                        let clonedGroup = JSON.parse(JSON.stringify(shared_group));

                        // Remove the current user from the group
                        clonedGroup.users = shared_group.users.filter(user => user.id !== auth.user.id);

                        // Add the current user to the beginning of the array
                        clonedGroup.users.unshift(matchedUser);

                        // First matched group...
                        if (typeof workspace.accessExplain === 'undefined') {

                            workspace.accessExplain = {};
                            workspace.accessExplain.type = 'group';
                            workspace.accessExplain.groups = [];
                            workspace.accessExplain.groups.push(clonedGroup);
                            workspace.accessExplain.permission = clonedGroup.permissions[0];

                            // Multiple matched groups...
                        } else {

                            let alreadyAdded = workspace.accessExplain.groups.find(group => group.id === shared_group.id);

                            if (typeof alreadyAdded === 'undefined') {

                                if (clonedGroup.permissions[0] === 'Admin') {
                                    workspace.accessExplain.permission = 'Admin';
                                } else if (clonedGroup.permissions[0] === 'Write' && workspace.accessExplain.permission !== 'Admin') {
                                    workspace.accessExplain.permission = 'Write';
                                }

                                // Add it only if it's not already in the array
                                workspace.accessExplain.groups.push(clonedGroup);
                            }
                        }
                    }
                }
            }
        }

        return workspaces;

    }

    const fetchWorkspaceByID = (workspace_id, isShare) => {

        updating.value = true

        if (typeof isShare === 'undefined') {
            isShare = false;
        }

        return new Promise((resolve, reject) => {

            if (typeof workspace_id === 'undefined') {
                return reject('No workspace ID provided');
            }

            let getRoute
            if (!isShare) {
                getRoute = '/workspaces/'+workspace_id;
            } else {
                getRoute = '/shared_workspace/'+workspace_id + '/' + isShare;
            }

            axios.get(getRoute)
                .then(response => {

                    updating.value = false
                    activeWorkspace.value = addUserAccess(response.data.data, isShare)
                    return resolve({'result': 'workspace_available'})

                }).catch(error => {

                    updating.value = false
                    reject(error)

                })

        })

    }

    /**
     * Fetch ALL workspaces from API
     */
    const fetchWorkspaces = () => {

        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/workspaces?checksum='+checksum.value)
                .then(response => {

                    checksum.value = response.data.checksum
                    if (!response.data.match) {
                        workspaces.value = addUserAccess(response.data.data)
                    }
                    updating.value = false
                    resolve()
                }).catch(error => {
                    updating.value = false
                    reject(error)
                })

            if (hasData.value){
                resolve()
            }
        })
    }

    const getWorkspaces = computed(() => {
        if (hasData.value) {
            return workspaces.value
        }

        return []
    })

    const getMostRecentlyUpdatedWorkspaces = (numberRequested) => {

        if (hasData.value) {

            // Sorting the array so that most recently updated comes first.
            return workspaces.value.sort((a, b) => {
                return (a.updated_at > b.updated_at);
            }).slice(0, numberRequested)

        }

        return []

    }

    function deleteSelected(delete_type, period, value) {

        updating.value = true

        let endpoint = '/workspaces/delete_by/'+delete_type;
        if (period || value) {
            endpoint += '/'+period+'/'+value;
        }

        return new Promise((resolve, reject) => {
            axios.get(endpoint)
                .then(response => {

                    updating.value = false
                    resolve(response.data);

                }).catch(error => {

                    updating.value = false
                    reject(error.data)

                })

        })

    }

    const fetchActiveWorkspace = (analysis_id, isShare)=>  {

        if (typeof isShare === 'undefined') {
            isShare = false;
        }

        let active_workspace_id = openAnalysis
                                    .getActiveWorkspaceID(analysis_id);

        if (active_workspace_id !== null) {
            const numericTest = /^[0-9]+$/;
            if (numericTest.test(active_workspace_id)) {
                return fetchWorkspaceByID(active_workspace_id, isShare);
            }
        }

        return new Promise.reject('No Workspace ID found.');

    }


    const fetchActiveWorkspaceViaShare = (shareID, type) => {

        updating.value = true

        return new Promise((resolve, reject) => {

            if (['dashboard', 'chart'].includes(type) === false) {
                return reject('Invalid share type');
            }

            // This uses the share ID to get the workspace ID, and then redirect to the dashboard.
            axios.get('/workspaces/'+type+'/share/load/'+shareID)
                .then(response => {

                    let active_workspace_id = response.data.valid_workspace_id;

                    if (active_workspace_id !== null) {
                        const numericTest = /^[0-9]+$/;
                        if (numericTest.test(active_workspace_id)) {

                            sessionStorage.setItem('share_'+shareID, JSON.stringify(response.data.share_details));

                            let searchAndWorkspaceData = {
                                workspaceData: {
                                    id: active_workspace_id
                                }
                            }

                            openAnalysis
                                .share_direct(searchAndWorkspaceData, '_self', 'shared_'+type, response.data.share_details)

                            resolve()

                        }
                    }

                    reject('Could not load')

                }).catch(error => {

                    console.error(error.data.error);

                    updating.value = false
                    reject(error)

                })

        })
    }

    /**
     * Attach or detach a label to/ from a workspace.
     */
    const label = (workspace, label, action) => {

        updating.value = true

        return new Promise((resolve, reject) => {
            axios.post('workspace/label/' + action, {workspace_id: workspace.id, label_id: label.id})
                .then(response => {
                    fetchWorkspaces()
                        .then(() => {
                            resolve(response)
                        });
                }).catch(err => {
                    reject(err.data)
                });
        });

    }

    const applyTheme = (workspace_id, theme_id) => {

        updating.value = true

        return new Promise((resolve, reject) => {
            axios.post('workspace/theme/' + (theme_id!==null?'apply':'remove'), {workspace_id: workspace_id, theme_id: theme_id})
                .then(response => {
                    fetchWorkspaces()
                        .then(() => {
                            resolve(response)
                        });
                }).catch(err => {
                reject(err.data)
            });
        });

    }

    const updateWorkspaceQueryMetric = (analysis_id, metricData) => {

        updating.value = true

        let active_workspace_id = openAnalysis
                                    .getActiveWorkspaceID(analysis_id);

        return new Promise((resolve, reject) => {
            axios.patch('workspaces/'+active_workspace_id+'/metrics', {metricData: metricData})
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err.data)
                });
        });
    }

    /**
     * Get the active queries for the current workspace
     */
    const getActiveQueries = (analysis_id, queryIndex) => {

        let active_workspace_id = openAnalysis
            .getActiveWorkspaceID(analysis_id);

        if (activeWorkspace.value === null) {
            console.error('The active workspace is not set.')
            return false;
        }

        // This would be bad!
        if (parseInt(active_workspace_id) !== parseInt(activeWorkspace.value[0].id)) {
            console.error('The active workspace is not the same as the one we are trying to get queries for.')
            return false;
        }

        if (activeWorkspace.value.length > 1) {
            console.error('The active workspace is not definitive! ('+activeWorkspace.value.length+' found)')
            return false;
        }

        // This is if we only want a specific query.
        if (typeof queryIndex !== 'undefined' && queryIndex !== null) {
            return activeWorkspace.value[0].queries[queryIndex];
        }

        return activeWorkspace.value[0].queries;
    }

    const getUsersGroupedQueryList = () => {

        let groupedQueries = [];
        for(const workspace of workspaces.value) {

            let thisWorkspace = {};
            thisWorkspace.label = workspace.name;
            thisWorkspace.options = workspace.queries.flatMap((query) => {
                return {'id': query.id,
                        'name': query.name,
                        'full_query': query.full_query,
                        'filters': query.filters
                }
            });

            groupedQueries.push(thisWorkspace);

        }

        return groupedQueries;

    }

    const fetchWorkspaceEngagement = (workspace_id) => {

        return new Promise((resolve, reject) => {
            axios.get('workspaces/'+workspace_id+'/engagement')
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err.data)
                });
        });

    }

    /**
     * Workspace grouping
     */
    const setGrouping = (analysis_id, grouping) => {

        let active_workspace_id = openAnalysis
            .getActiveWorkspaceID(analysis_id);

        return new Promise((resolve, reject) => {
            axios.patch('/workspaces/' + active_workspace_id, {'grouping': grouping})
                .then(response => {
                    fetchActiveWorkspace(analysis_id);
                    resolve(response)
                }).catch(err => {
                    reject(err.data)
                });
        });

    }

    /*********************
     *** Chart Sharing ***
     *********************/

    const shareChart = (analysis_id, analysis_section) => {

        let active_workspace_id = openAnalysis
            .getActiveWorkspaceID(analysis_id);

        let noOfQueries = openAnalysis
            .getActiveWorkspaceQueryCount(analysis_id);

        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/workspaces/' + active_workspace_id + '/chart/share/' + analysis_section+ '/' + noOfQueries)
                .then((response) => {

                    updating.value = false
                    resolve(response.data);

                }).catch((errorResponse) => {

                    console.error(errorResponse.data?.error)

                    updating.value = false
                    reject(errorResponse.data?.error)

                })
        })
    }

    const updateSharedChart = (analysis_id, workspace_id, shareKey, data) => {

        updating.value = true

        let active_workspace_id;
        if (workspace_id !== null) {
            active_workspace_id = workspace_id;
        } else {
            active_workspace_id = openAnalysis
                .getActiveWorkspaceID(analysis_id);
        }

        data['key'] = shareKey;

        return new Promise((resolve, reject) => {
            axios.patch('/workspaces/' + active_workspace_id + '/chart/update_share', data)
                .then((response) => {

                    updating.value = false
                    resolve(response.data);

                }).catch((errorResponse) => {

                    updating.value = false
                    reject(errorResponse.data)

                })

        })
    }

    const loadSharedChart = (analysis_id, workspace_id, shareKey) => {

        updating.value = true

        let active_workspace_id;
        if (workspace_id !== null) {
            active_workspace_id = workspace_id;
        } else {
            active_workspace_id = openAnalysis
                .getActiveWorkspaceID(analysis_id);
        }

        return new Promise((resolve, reject) => {

            if (active_workspace_id === false) {
                reject({'result': 'error', 'msg':'Active workspace could not be identified.'})
            }

            axios.get('/workspaces/'+active_workspace_id+'/chart/share/'+shareKey)
                .then((response) => {

                    updating.value = false
                    resolve(response.data);

                }).catch((errorResponse) => {

                updating.value = false
                reject(errorResponse.data)

            })

        })

    }

    const deleteSharedChart = (analysis_id, workspace_id, shareKey) => {

        updating.value = true

        let active_workspace_id;
        if (workspace_id !== null) {
            active_workspace_id = workspace_id;
        } else {
            active_workspace_id = openAnalysis
                .getActiveWorkspaceID(analysis_id);
        }

        return new Promise((resolve, reject) => {

            if (active_workspace_id === false) {
                reject({'result': 'error', 'msg':'Active workspace could not be identified.'})
            }

            axios.delete('/workspaces/'+active_workspace_id+'/chart/delete_share/'+shareKey)
                .then((response) => {

                    updating.value = false
                    resolve(response.data);

                }).catch((errorResponse) => {

                    updating.value = false
                    reject(errorResponse.data)

                })

        })
    }

    /*************************
     *** Dashboard Sharing ***
     ************************/

    const shareDashboard = (analysis_id) => {

        updating.value = true

        let active_workspace_id = openAnalysis
            .getActiveWorkspaceID(analysis_id);

        let noOfQueries = openAnalysis
            .getActiveWorkspaceQueryCount(analysis_id);

        return new Promise((resolve, reject) => {
            axios.get('/workspaces/' + active_workspace_id + '/dashboard/share/' + noOfQueries)
                .then((response) => {

                    updating.value = false
                    resolve(response.data);

                }).catch((errorResponse) => {

                    console.error(errorResponse.data?.error)

                    updating.value = false
                    reject(errorResponse.data?.error)

                })

        })

    }

    const updateSharedDashboard = (analysis_id, workspace_id, shareKey, data) => {

        updating.value = true

        let active_workspace_id;
        if (workspace_id !== null) {
            active_workspace_id = workspace_id;
        } else {
            active_workspace_id = openAnalysis
                .getActiveWorkspaceID(analysis_id);
        }


        data['key'] = shareKey;

        return new Promise((resolve, reject) => {
            axios.patch('/workspaces/'+active_workspace_id+'/dashboard/update_share', data)
                .then((response) => {

                    updating.value = false
                    resolve(response.data);

                }).catch((errorResponse) => {

                    updating.value = false
                    reject(errorResponse.data)

                })

        })

    }

    const deleteSharedDashboard = (analysis_id, workspace_id, shareKey) => {

        updating.value = true

        let active_workspace_id;
        if (workspace_id !== null) {
            active_workspace_id = workspace_id;
        } else {
            active_workspace_id = openAnalysis
                .getActiveWorkspaceID(analysis_id);
        }

        return new Promise((resolve, reject) => {
            axios.delete('/workspaces/'+active_workspace_id+'/dashboard/delete_share/'+shareKey)
                .then((response) => {

                    updating.value = false
                    resolve(response.data);

                }).catch((errorResponse) => {

                    updating.value = false
                    reject(errorResponse.data)

                })

        })
    }

    const loadSharedDashboard = (analysis_id, workspace_id, shareKey) => {

        updating.value = true

        let active_workspace_id;
        if (workspace_id !== null) {
            active_workspace_id = workspace_id;
        } else {
            active_workspace_id = openAnalysis
                .getActiveWorkspaceID(analysis_id);
        }

        return new Promise((resolve, reject) => {

            if (active_workspace_id === false) {
                reject({'result': 'error', 'msg':'Active workspace could not be identified.'})
            }

            axios.get('/workspaces/'+active_workspace_id+'/dashboard/share/'+shareKey)
                .then((response) => {

                    updating.value = false
                    resolve(response.data);

                }).catch((errorResponse) => {

                updating.value = false
                reject(errorResponse.data)

            })

        })

    }

    return {
        fetchWorkspaces,
        fetchActiveWorkspace,

        getMostRecentlyUpdatedWorkspaces,
        updateWorkspaceQueryMetric,
        getActiveQueries,
        getUsersGroupedQueryList,
        fetchWorkspaceEngagement,
        deleteSelected,
        setGrouping,

        shareChart,
        updateSharedChart,
        deleteSharedChart,
        loadSharedChart,

        shareDashboard,
        updateSharedDashboard,
        deleteSharedDashboard,
        loadSharedDashboard,
        fetchActiveWorkspaceViaShare,

        label,

        applyTheme,

        getWorkspaces,
        updating,
        hasData,
        workspaces,
        activeWorkspace
    }

})
