export const patentSearchFilters = [
    'assignees_name_probable',
    'assignees_name_original',
    'ultimate_owner',
    'country',
    'applicationdate',
    'publicationdate',
    'earliestprioritydate',
    'type',
    'cpcvalues',
    'ipcrvalues',
    'ipctechnologyfield',
    'inventors_name_standard',
    'ratings',
    'labels',
    'raw',
    'expectedexpirydate',
    'grant',
    'pending',
    'sepsexists',
    'seps_standard_organization',
    'seps_etsi_project',
    'agents_name_normalized',
]
