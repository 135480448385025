import { createWebHistory, createRouter } from 'vue-router'
import { useNProgress } from '@vueuse/integrations/useNProgress'
import { useWrapPromises } from './Composables/Navigation/WrapPromises'
import { useAuthStore } from './Stores/Auth'
import { useOrganizationStore } from './Stores/Organization'
import { useFoldersStore } from './Stores/Folders'
import { useFolderStore } from './Stores/Folder'
import { useFolderCategoriesStore } from './Stores/FolderCategories'
import { useFolderLabelsStore } from './Stores/FolderLabels'
import { useListedPatentsStore } from './Stores/ListedPatents'
import { useStrategiesStore } from './Stores/Strategies'
import { useSearchTemplateStore } from './Stores/SearchTemplate'
import { useUserStore } from './Stores/Users'
import { useGroupStore } from './Stores/Groups'
import { useAlertsStore } from './Stores/Alerts'
import { useAlertRunStore } from './Stores/AlertRun'
import { useLoggedEventsStore } from './Stores/LoggedEvents'
import { useHighlightingSchemesStore } from "@/Stores/HighlightingSchemes";
import { useActiveTrackingReferenceStore } from "./Stores/ActiveTrackingReference"
import { usePublishedSearchStore } from './Stores/PublishedSearch'
import { useExportsStore } from './Stores/Exports'
import { useCustomFieldSchemesStore } from './Stores/CustomFieldSchemes'
import { useCustomFieldSchemeStore } from './Stores/CustomFieldScheme'
import { useDynamicModalStore } from '@/Stores/DynamicModal'
import { useOriginPlusStore } from "@/Stores/OriginPlus";
import {
    GetAlertRuns,
    GetPatent,
    GetExport,
    GetSearch,
    GetSubscriptionRuns,
    VerifySharedSearchLink,
    RequireChemicalExlorer,
    GetSharedPatent,
    VerifySharedAnalysisLink,
    VerifyPublishedFolderLink
} from './Middleware'
import TrackingModal from '@/Components/Tracking/TrackingModal.vue'
import {MakeInchiKeySearch} from "@/Middleware/MakeInchiKeySearch";
import {useSubscriptionRunStore} from '@/Stores/SubscriptionRun'
import {useWorkspacesStore} from "@/Stores/Analysis/Workspaces/Workspaces";
import {useWorkspaceLabelsStore} from "@/Stores/Analysis/Workspaces/WorkspaceLabels";
import {useFieldsAndGroupsStore} from "@/Stores/Analysis/FieldsAndGroups";

const { isLoading } = useNProgress()

const Login = () => import('./Pages/Auth/Login.vue')
const SetPassword = () => import('./Pages/Auth/SetPassword.vue')
const ForgotPassword = () => import('./Pages/Auth/ForgotPassword.vue')
const ResetPassword = () => import('./Pages/Auth/ResetPassword.vue')
const NotFound = () => import('./Pages/Errors/NotFound.vue')
const Expired = () => import('./Pages/Errors/Expired.vue')
const PdfNotFound = () => import('./Pages/Errors/PdfNotFound.vue')
const Dashboard = () => import('./Pages/Dashboard.vue')
const Activity = () => import('./Pages/Activity.vue')
const Search = () => import('./Pages/Search.vue')
const SearchResults = () => import ('./Pages/SearchResults.vue')
const SearchBrowse = () => import('./Pages/SearchBrowse.vue')
const ExportSearch = () => import('./Pages/ExportSearch.vue')
const ExportDownload = () => import('./Pages/ExportDownload.vue')
const PatentRecord = () => import('./Pages/PatentRecord.vue')
const LegalPopout = () => import('./Pages/Popouts/Legal.vue')
const CitationsPopout = () => import('./Pages/Popouts/Citations.vue')
const FullTextPopout = () => import('./Pages/Popouts/FullText.vue')
const ImagesPopout = () => import('./Pages/Popouts/Images.vue')
const History = () => import('./Pages/History.vue')
const Exports = () => import('./Pages/Exports.vue')
const Folders = () => import('./Pages/Folders.vue')
const FolderPatents = () => import('./Pages/FolderPatents.vue')
const FolderPatentsBrowse = () => import('./Pages/FolderPatentsBrowse.vue')
const FolderNpl = () => import('./Pages/FolderNpl.vue')
const FolderActivity = () => import('./Pages/FolderActivity.vue')
const FolderPermissions = () => import('./Pages/FolderPermissions.vue')
const FolderLabels = () => import('./Pages/FolderLabels.vue')
const Alerts = () => import('./Pages/Alerts.vue')
const AlertRuns = () => import('./Pages/AlertRuns.vue')
const AlertResults = () => import('./Pages/AlertResults.vue')
const AlertResultsBrowse = () => import('./Pages/AlertResultsBrowse.vue')
const AlertResultsAccess = () => import('./Pages/AlertResultsAccess.vue')
const ShareAlertRunResults = () => import('./Pages/Share/AlertRunResults.vue')
const ShareAlertRunBrowse = () => import('./Pages/Share/AlertRunBrowse.vue')
const ChemicalExplorer = () => import('./Pages/ChemicalExplorer.vue')
const ChemicalExplorerResults = () => import('./Pages/ChemicalExplorerResults.vue')
const NplSearch = () => import('./Pages/NplSearch.vue')
const NplSearchResults = () => import('./Pages/NplSearchResults.vue')
const Users = () => import('./Pages/Users.vue')
const Groups = () => import('./Pages/Groups.vue')
const PatentCollection = () => import('./Pages/PatentCollection.vue')
const Tracking = () => import('./Pages/Tracking.vue')
const Settings = () => import('./Pages/Settings.vue')
const AnalysisWorkspaces = () => import('./Pages/Analysis/Workspaces.vue')

const AnalysisDashboard = () => import('./Pages/Analysis/Dashboard.vue')
const SharedAnalysisDashboard = () => import('./Pages/Analysis/Shared/Dashboard.vue')
const AnalysisInit = () => import('./Pages/Analysis/Init.vue')
const AnalysisChart = () => import('./Pages/Analysis/Chart.vue')
const SharedAnalysisChart = () => import('./Pages/Analysis/Shared/Chart.vue')

const SharedPatentRecord = () => import('./Pages/Share/PatentRecord.vue')
const PublishedSearchViewerSelector = () => import('./Pages/Share/SelectViewer.vue')
const PublicViewer = () => import('./Pages/Share/PublicViewer.vue')
const PublicBrowser = () => import('./Pages/Share/PublicBrowser.vue')
const PublishedAnalysisViewerSelector = () => import('./Pages/Share/SelectAnalysisViewer.vue')
const ViewInPatbase = () => import('./Pages/ViewInPatbase.vue')
const CustomFieldSchemes = () => import('./Pages/CustomFieldSchemes.vue')
const CustomFieldSchemeFields = () => import('./Pages/CustomFieldSchemeFields.vue')
const CustomFieldSchemePermissions = () => import('./Pages/CustomFieldSchemePermissions.vue')
const OriginPlusAlerts = () => import('./Pages/OriginPlus/OriginPlusAlerts.vue')
const OriginPlusTasks = () => import('./Pages/OriginPlus/OriginPlusTasks.vue')
const OriginPlusSettings = () => import('./Pages/OriginPlus/OriginPlusSettings.vue')
const OriginPlusAlertRuns = () => import('./Pages/OriginPlus/OriginPlusAlertRuns.vue')

const OriginPlusSubscriptions = () => import('./Pages/OriginPlus/OriginPlusSubscriptions.vue')
const OriginPlusSubscriptionRuns = () => import('./Pages/OriginPlus/OriginPlusSubscriptionRuns.vue')
const OriginPlusSubscriptionRunResults = () => import('./Pages/OriginPlus/OriginPlusSubscriptionRunResults.vue')
const OriginPlusSubscriptionRunResultsBrowse = () => import('./Pages/OriginPlus/OriginPlusSubscriptionRunResultsBrowse.vue')


const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    },
    {
        path: '/expired',
        name: 'Expired',
        component: Expired,
    },
    {
        path: '/pdf-not-found/:ucid?',
        props: true,
        name: 'PdfNotFound',
        component: PdfNotFound,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { guest: true },
    },
    {
        path: '/set-password',
        name: 'SetPassword',
        component: SetPassword,
        meta: { guest: true },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: { guest: true },
    },
    {
        path: '/password-reset/:token',
        name: 'ResetPassword',
        component: ResetPassword,
        props: route => ({ token: route.params.token }),
        meta: { guest: true },
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            endUserRestricted: true,
        },
        beforeEnter(to) {
            useStrategiesStore().fetchCurrentStrategy()
            useFolderStore().fetchGlobalFolderLimit()
            useFoldersStore().fetchFolders()
            return true
        }
    },
    {
        path: '/activity',
        name: 'Activity',
        component: Activity,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useLoggedEventsStore().fetchLoggedEvents(),
                useUserStore().fetchUsers(),
            ]))
        }
    },
    {
        path: '/search',
        name: 'Search',
        component: Search,
        meta: {
            requiresAuth: true,
            loadsData: true,
            readOnlyRestricted: true,
        },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useStrategiesStore().fetchCurrentStrategy(),
                useSearchTemplateStore().fetchDefaultTemplate(),
                useCustomFieldSchemesStore().fetchActiveScheme(),
            ]))
        }
    },
    {
        path: '/search/:index',
        name: 'Search.Results',
        component: SearchResults,
        meta: { requiresAuth: true, loadsData: true },
        props: true,
        beforeEnter: [
            () => {
                useFoldersStore().fetchFolders()
                useListedPatentsStore().fetchPatentList()
                useCustomFieldSchemesStore().fetchActiveScheme().catch()
                useUserStore().fetchUsers()
            },
            GetSearch,
        ],
    },
    {
        path: '/search/:index/browse',
        name: 'Search.Browse',
        component: SearchBrowse,
        meta: { requiresAuth: true, loadsData: true },
        props: true,
        beforeEnter: [
            () => {
                useFoldersStore().fetchFolders()
                useAlertsStore().fetchAlerts()
                useCustomFieldSchemesStore().fetchActiveScheme()
                useUserStore().fetchUsers()
            },
            GetSearch,
        ],
    },
    {
        path: '/search/history',
        name: 'History',
        component: History,
        meta: {
            requiresAuth: true,
            loadsData: true,
            endUserRestricted: true,
            readOnlyRestricted: true,
        },
        beforeEnter() {
            const strategiesStore = useStrategiesStore()

            return useWrapPromises(Promise.all([
                strategiesStore.fetchCurrentStrategy(),
                strategiesStore.fetchStrategies(),
            ]))
        }
    },
    {
        path: '/exports',
        name: 'Exports',
        component: Exports,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter() {
            return useWrapPromises(useExportsStore().fetchExports())
        }
    },
    {
        path: '/search/exports/:id',
        name: 'ExportSearch',
        component: ExportSearch,
        meta: { requiresAuth: true, loadsData: true },
        props: true,
        beforeEnter: [GetExport],
    },
    {
        path: '/search/exports/:id/download',
        name: 'ExportDownload',
        component: ExportDownload,
        meta: { requiresAuth: true, loadsData: true },
        props: true,
        beforeEnter: [GetExport],
    },
    {
        path: '/patent/:ucid',
        name: 'Patent',
        component: PatentRecord,
        meta: { requiresAuth: true, loadsData: true },
        props: true,
        beforeEnter: [
            () => useFoldersStore().fetchFolders(),
            GetPatent,
        ],
    },
    {
        path: '/patent/:ucid/legal',
        name: 'Popouts.Legal',
        component: LegalPopout,
        meta: { requiresAuth: true },
        props: true,
    },
    {
        path: '/patent/:ucid/citations',
        name: 'Popouts.Citations',
        component: CitationsPopout,
        meta: { requiresAuth: true },
        props: true,
    },
    {
        path: '/patent/:ucid/fulltext',
        name: 'Popouts.FullText',
        component: FullTextPopout,
        meta: { requiresAuth: true },
        props: true,
    },
    {
        path: '/patent/:ucid/images',
        name: 'Popouts.Images',
        component: ImagesPopout,
        meta: { requiresAuth: false },
        beforeEnter(to) {
            let auth = useAuthStore();
            if (!auth.isAuthenticated) {
                if (to.query.key) {
                    return useWrapPromises(auth.checkKey(to.params.ucid, to.query.key))
                        .catch((e) => {
                            return {name: 'NotFound'}
                        })
                }
                return { name: 'Login' }
            }
        },
        props: true,
    },
    {
        path: '/folders',
        name: 'Folders',
        component: Folders,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useFoldersStore().fetchFolders(),
                useFolderCategoriesStore().fetchFolderCategories(),
                useFolderStore().fetchGlobalFolderLimit()
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/folders/:folder_id/patents',
        alias: '/folders/:folder_id',
        name: 'Folders.Patents',
        component: FolderPatents,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            useFolderLabelsStore().fetchLabels(to.params.folder_id)

            return useWrapPromises(
                useFolderStore().fetchFolder(to.params.folder_id),
                useCustomFieldSchemesStore().fetchActiveScheme(),
                useUserStore().fetchUsers()
            )
                .catch(() => ({ name: 'NotFound' }))
        },
    },
    {
        path: '/folders/:folder_id/patents/browse',
        name: 'Folders.Patents.Browse',
        component: FolderPatentsBrowse,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            useFolderLabelsStore().fetchLabels(to.params.folder_id)

            return useWrapPromises(
                useFolderStore().fetchFolder(to.params.folder_id),
                useCustomFieldSchemesStore().fetchActiveScheme(),
                useUserStore().fetchUsers(),
            )
                .catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/folders/:folder_id/npl',
        name: 'Folders.Npl',
        component: FolderNpl,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            useFolderLabelsStore().fetchLabels(to.params.folder_id)

            return useWrapPromises(useFolderStore().fetchFolder(to.params.folder_id))
                .catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/folders/:folder_id/activity',
        name: 'Folders.Activity',
        component: FolderActivity,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useFolderStore().fetchFolder(to.params.folder_id),
                useLoggedEventsStore().fetchFolderEvents(to.params.folder_id),
                useUserStore().fetchUsers(),
            ])).catch(() => ({ name: 'NotFound' }))
        },
    },
    {
        path: '/folders/:folder_id/permissions',
        name: 'Folders.Permissions',
        component: FolderPermissions,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useFolderStore().fetchFolder(to.params.folder_id),
                useUserStore().fetchUsers(),
                useGroupStore().fetchGroups(),
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/folders/:folder_id/labels',
        name: 'Folders.Labels',
        component: FolderLabels,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useFolderLabelsStore().fetchLabels(to.params.folder_id),
                useFolderStore().fetchFolder(to.params.folder_id),
            ])).catch(() => ({ name: 'NotFound' }))
        },
    },
    {
        path: '/alerts',
        name: 'Alerts',
        component: Alerts,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useAlertsStore().fetchAlerts(),
                useFoldersStore().fetchFolders(),
                useFolderStore().fetchGlobalFolderLimit(),
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/alerts/:id',
        name: 'Alerts.Runs',
        component: AlertRuns,
        meta: { requiresAuth: true, loadsData: true },
        props: true,
        beforeEnter:[
            GetAlertRuns,
            () => {
            return useWrapPromises(Promise.all([
                useFoldersStore().fetchFolders(),
                useFolderStore().fetchGlobalFolderLimit()
                ])).catch(() => ({ name: 'NotFound' }))
            }
        ]
    },
    {
        path: '/alert_runs/:uuid',
        name: 'AlertRuns.Results',
        component: AlertResults,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useAlertRunStore().fetchAlertRun(to.params.uuid),
                useFoldersStore().fetchFolders(),
                useFolderStore().fetchGlobalFolderLimit(),
                useListedPatentsStore().fetchPatentList(),
                useAlertsStore().fetchAlerts(),
                useCustomFieldSchemesStore().fetchActiveScheme(),
                useUserStore().fetchUsers()
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/alert_runs/:uuid/browse',
        name: 'AlertRuns.Results.Browse',
        component: AlertResultsBrowse,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useAlertRunStore().fetchAlertRun(to.params.uuid),
                useFoldersStore().fetchFolders(),
                useListedPatentsStore().fetchPatentList(),
                useCustomFieldSchemesStore().fetchActiveScheme(),
                useUserStore().fetchUsers()
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/chemical-explorer',
        name: 'ChemicalExplorer',
        component: ChemicalExplorer,
        meta: {
            requiresAuth: true,
            endUserRestricted: true,
            readOnlyRestricted: true,
        },
        beforeEnter: [RequireChemicalExlorer],
    },
    {
        path: '/chemical-explorer/results',
        name: 'ChemicalExplorer.Results',
        component: ChemicalExplorerResults,
        meta: {
            requiresAuth: true,
            endUserRestricted: true,
            readOnlyRestricted: true
        },
        beforeEnter: [RequireChemicalExlorer]
    },
    {
        path: '/chemical-explorer/inchikey/:inchikey/:identifier(ucid|fam|efam)?/:ucid?',
        name: 'ChemicalExplorer.InchiKeySearch',
        meta: {
            requiresAuth: true,
            endUserRestricted: true,
            readOnlyRestricted: true
        },
        beforeEnter: [MakeInchiKeySearch]
    },
    {
        path: '/npl',
        name: 'NplSearch',
        component: NplSearch,
        meta: {
            requiresAuth: true,
            readOnlyRestricted: true
        },
    },
    {
        path: '/npl/results',
        name: 'NplSearch.Results',
        component: NplSearchResults,
        meta: {
            requiresAuth: true,
            loadsData: true,
            readOnlyRestricted: true
        },
        beforeEnter(to) {
            return useWrapPromises(useFoldersStore().fetchFolders())
        }
    },
    {
        path: '/users/:query?',
        name: 'Users',
        component: Users,
        meta: { requiresAuth: true, requiresAdmin: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useUserStore().fetchUsers(),
                useOrganizationStore().fetchOrganization(),
            ]))
        }
    },
    {
        path: '/tracking',
        name: 'Tracking',
        component: Tracking,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            const trackingStore = useActiveTrackingReferenceStore()

            return useWrapPromises(Promise.all([
                trackingStore.fetchTrackingIdentifiers(),
                trackingStore.fetchTrackingLogs({ dates: ['this_month'] }),
                useUserStore().fetchUsers()
            ]))
        }
    },
    {
        path: '/groups/:query?',
        name: 'Groups',
        component: Groups,
        meta: { requiresAuth: true, requiresAdmin: true, loadsData: true},
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useUserStore().fetchUsers(),
                useGroupStore().fetchGroups(),
                useOrganizationStore().fetchOrganization(),
            ]))
        }
    },
    {
        path: '/patent-collection',
        name: 'PatentCollection',
        component: PatentCollection,
        meta: { requiresAuth: true },
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: { requiresAuth: true },
    },
    {
        path: '/analysis/workspaces/:analysis_id?',
        name: 'AnalysisWorkspaces',
        component: AnalysisWorkspaces,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter() {
            return useWrapPromises(Promise.all([
                useWorkspaceLabelsStore().fetchLabels(),
                useWorkspacesStore().fetchWorkspaces()
            ]))
        }
    },
    {
        path: '/analysis/init',
        name: 'AnalysisInit',
        component: AnalysisInit,
        meta: { requiresAuth: true },
        beforeEnter() {
            return useWrapPromises(
                // Fetch and cache the field names and groups
                useFieldsAndGroupsStore().fetchFields()
            ).catch((error) => {
                console.error(error);
            })
        }
    },
    {
        path: '/analysis/:analysis_id',
        name: 'AnalysisDashboard',
        component: AnalysisDashboard,
        meta: { requiresAuth: true },
        // Use async beforeEnter to ensure thar the data is fetched before navigating to the route
        async beforeEnter(to, from, next) {
            try {

                await Promise.all([
                    // Fetch field names and groups (hopefully cached already)
                    useFieldsAndGroupsStore().fetchFields(),
                    useWorkspacesStore().fetchActiveWorkspace(to.params.analysis_id),
                    useWorkspacesStore().fetchWorkspaces()
                ]);

                next(); // Proceed with navigation
            } catch (error) {
                next({
                    name: 'AnalysisWorkspaces',
                    query: { error: 'noActiveWorkspace' }
                });
            }
        }
    },
    {
        path: '/analysis/:analysis_id/section/:analysis_section/chart/:chart_id?',
        name: 'Chart',
        component: AnalysisChart,
        meta: { requiresAuth: true },
        // Use async beforeEnter to ensure thar the data is fetched before navigating to the route
        async beforeEnter(to, from, next) {
            try {

                await Promise.all([
                    useFieldsAndGroupsStore().fetchFields(),
                    useWorkspacesStore().fetchActiveWorkspace(to.params.analysis_id),
                    useWorkspacesStore().fetchWorkspaces()
                ]);

                next(); // Proceed with navigation
            } catch (error) {
                next({
                    name: 'AnalysisWorkspaces',
                    query: { error: 'noActiveWorkspace' }
                });
            }
        }

    },
    {
        path: '/view-in-patbase',
        name: 'ViewInPatbase',
        component: ViewInPatbase,
        meta: { requiresAuth: true },
        beforeEnter: () => {
            // Reject the navigation if the user doesn't have the appropriate module
            if (! useAuthStore().hasModule('patbase_viewer')) {
                return { name: 'NotFound' }
            }
        }
    },
    {
        path: '/custom-field-schemes',
        name: 'CustomFieldSchemes',
        component: CustomFieldSchemes,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useCustomFieldSchemesStore().fetchSchemes(),
                useCustomFieldSchemesStore().fetchActiveScheme()
            ]))
        }
    },
    {
        path: '/custom-field-schemes/:scheme_id',
        name: 'CustomFieldSchemes.Fields',
        component: CustomFieldSchemeFields,
        props: true,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useCustomFieldSchemeStore().fetchScheme(to.params.scheme_id),
            ]))
        }
    },
    {
        path: '/custom-field-schemes/:scheme_id/permissions',
        name: 'CustomFieldSchemes.Permissions',
        component: CustomFieldSchemePermissions,
        props: true,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useCustomFieldSchemeStore().fetchScheme(to.params.scheme_id),
                useUserStore().fetchUsers(),
                useGroupStore().fetchGroups(),
            ]))
        }
    },
    {
        path: '/origin-plus/alerts',
        name: 'OriginPlus.Alerts',
        component: OriginPlusAlerts,
        meta: {
            requiresAuth: true,
            loadsData: true,
            originPlus: true,
            requiresAdmin: true,
        },
        beforeEnter() {
            return useWrapPromises(Promise.all([
                useUserStore().fetchUsers(),
                useGroupStore().fetchGroups(),
                useHighlightingSchemesStore().fetchSchemes(),
                useOriginPlusStore().fetchAlerts(),
                useCustomFieldSchemesStore().fetchSchemes(),
                useCustomFieldSchemesStore().fetchActiveScheme()
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/origin-plus/alerts/:id',
        name: 'OriginPlus.AlertRuns',
        component: OriginPlusAlertRuns,
        meta: {
            requiresAuth: true,
            loadsData: true,
            originPlus: true,
            requiresAdmin: true,
        },
        props: true,
        beforeEnter:[
            GetAlertRuns,
            () => {
                return useWrapPromises(Promise.all([
                    useFoldersStore().fetchFolders(),
                    useFolderStore().fetchGlobalFolderLimit()
                ])).catch(() => ({ name: 'NotFound' }))
            }
        ]
    },
    {
        path: '/origin-plus/alert_runs/:uuid',
        name: 'OriginPlus.AlertRuns.Results',
        component: AlertResults,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useAlertRunStore().fetchAlertRun(to.params.uuid),
                useFoldersStore().fetchFolders(),
                useFolderStore().fetchGlobalFolderLimit(),
                useListedPatentsStore().fetchPatentList(),
                useAlertsStore().fetchAlerts(),
                useCustomFieldSchemesStore().fetchActiveScheme(),
                useUserStore().fetchUsers()
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/origin-plus/alert_runs/:uuid/browse',
        name: 'OriginPlus.AlertRuns.Results.Browse',
        component: AlertResultsBrowse,
        meta: { requiresAuth: true, loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useAlertRunStore().fetchAlertRun(to.params.uuid),
                useFoldersStore().fetchFolders(),
                useListedPatentsStore().fetchPatentList(),
                useCustomFieldSchemesStore().fetchActiveScheme(),
                useUserStore().fetchUsers()
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/origin-plus/tasks',
        name: 'OriginPlus.Tasks',
        component: OriginPlusTasks,
        meta: { requiresAuth: true, loadsData: true, originPlus: true },
        beforeEnter() {
            return useWrapPromises(Promise.all([
                useOriginPlusStore().fetchTasks(),
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/origin-plus/tasks/:uuid',
        name: 'OriginPlus.Tasks.Results',
        component: AlertResults,
        meta: { requiresAuth: true, loadsData: true, originPlus: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useOriginPlusStore().fetchTask(to.params.uuid),
                useFoldersStore().fetchFolders(),
                useFolderStore().fetchGlobalFolderLimit(),
                useListedPatentsStore().fetchPatentList(),
                useAlertsStore().fetchAlerts(),
                useCustomFieldSchemesStore().fetchActiveScheme(to.params.uuid),
                useUserStore().fetchUsers()
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/origin-plus/tasks/:uuid/browse',
        name: 'OriginPlus.Tasks.Results.Browse',
        component: AlertResultsBrowse,
        meta: { requiresAuth: true, loadsData: true, originPlus: true },
        beforeEnter(to) {
            return useWrapPromises(Promise.all([
                useOriginPlusStore().fetchTask(to.params.uuid),
                useFoldersStore().fetchFolders(),
                useListedPatentsStore().fetchPatentList(),
                useCustomFieldSchemesStore().fetchActiveScheme(to.params.uuid),
                useUserStore().fetchUsers()

            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/origin-plus/alerts/subscriptions',
        name: 'OriginPlus.Subscriptions',
        component: OriginPlusSubscriptions,
        meta: { requiresAuth: true, loadsData: true, originPlus: true },
        beforeEnter() {
            return useWrapPromises(Promise.all([
                useUserStore().fetchUsers(),
                useHighlightingSchemesStore().fetchSchemes(),
                useOriginPlusStore().fetchSubscriptions(),
                useCustomFieldSchemesStore().fetchSchemes(true),
                useCustomFieldSchemesStore().fetchActiveScheme()
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/origin-plus/alerts/subscriptions/:id',
        name: 'OriginPlus.SubscriptionRuns',
        component: OriginPlusSubscriptionRuns,
        meta: { requiresAuth: true, loadsData: true, originPlus: true },
        props: true,
        beforeEnter: [
            GetSubscriptionRuns,
            () => {
                return useWrapPromises(Promise.all([
                    useFoldersStore().fetchFolders(),
                    useFolderStore().fetchGlobalFolderLimit()
                ])).catch(() => ({name: 'NotFound'}))
            }
        ]
    },
    {
        path: '/origin-plus/alerts/subscriptions/:id/subscription-runs/:run_id',
        name: 'OriginPlus.SubscriptionRunResults',
        component: OriginPlusSubscriptionRunResults,
        meta: { requiresAuth: true, loadsData: true, originPlus: true },
        props: true,
        beforeEnter(to) {
            useFoldersStore().fetchFolders()
            useListedPatentsStore().fetchPatentList()
            useUserStore().fetchUsers()

            return useWrapPromises(Promise.all([
                useSubscriptionRunStore().fetchSubscriptionRun(to.params.id, to.params.run_id),
                useCustomFieldSchemesStore().fetchActiveScheme().catch(),
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/origin-plus/alerts/subscriptions/:id/subscription-runs/:run_id/browse',
        name: 'OriginPlus.SubscriptionRunResults.Browse',
        component: OriginPlusSubscriptionRunResultsBrowse,
        meta: { requiresAuth: true, loadsData: true, originPlus: true },
        props: true,
        beforeEnter(to) {
            useFoldersStore().fetchFolders()
            useListedPatentsStore().fetchPatentList()
            useUserStore().fetchUsers()

            return useWrapPromises(Promise.all([
                useSubscriptionRunStore().fetchSubscriptionRun(to.params.id, to.params.run_id),
                useCustomFieldSchemesStore().fetchActiveScheme().catch(),
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/origin-plus/settings',
        name: 'OriginPlus.Settings',
        component: OriginPlusSettings,
        meta: { requiresAuth: true, loadsData: true, originPlus: true },
        beforeEnter() {
            return useWrapPromises(Promise.all([
                useOriginPlusStore().fetchTasks(),
            ])).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/share/patent/:ucid/:key',
        name: 'Share.Patent',
        component: SharedPatentRecord,
        meta: { loadsData: true },
        beforeEnter: [GetSharedPatent],
        props: true,
    },
    {
        path: '/share/search/:uuid',
        name: 'Share.Search',
        component: PublishedSearchViewerSelector,
        meta: { loadsData: true },
        beforeEnter: [VerifySharedSearchLink],
        props: true,
    },
    {
        path: '/share/search/:uuid/results',
        name: 'Share.Search.Viewer',
        component: PublicViewer,
        meta: { loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(usePublishedSearchStore().fetchPublishedSearch(to.params.uuid))
                .catch(() => ({ name: 'NotFound' }))
        },
        props: true,
    },
    {
        path: '/share/search/:uuid/results/browse',
        name: 'Share.Search.Browser',
        meta: { loadsData: true },
        component: PublicBrowser,
        beforeEnter(to) {
            return useWrapPromises(usePublishedSearchStore().fetchPublishedSearch(to.params.uuid))
                .catch(() => ({ name: 'NotFound' }))
        },
        props: true,
    },
    {
        path: '/share/alert_runs/:uuid',
        name: 'Share.AlertRuns.Access',
        component: AlertResultsAccess,
        meta: { loadsData: true },
        beforeEnter(to) {
            return useWrapPromises(useAlertRunStore().fetchAlertRun(to.params.uuid))
                .catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/share/alert_runs/:uuid/results',
        name: 'Share.AlertRuns.Results',
        meta: { loadsData: true },
        component: ShareAlertRunResults,
        beforeEnter(to) {
            return useWrapPromises(useAlertRunStore().fetchAlertRun(to.params.uuid))
                .catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/share/alert_runs/:uuid/results/browse',
        name: 'Share.AlertRuns.Browse',
        meta: { loadsData: true },
        component: ShareAlertRunBrowse,
        beforeEnter(to) {
            return useWrapPromises(useAlertRunStore().fetchAlertRun(to.params.uuid))
                .catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        path: '/share/folder/:uuid',
        name: 'Share.Folder',
        component: PublishedSearchViewerSelector,
        meta: {loadsData: true},
        beforeEnter: [VerifyPublishedFolderLink],
        props: true,
    },

    {
        path: '/share/folder/:uuid/results',
        name: 'Share.Folder.Viewer',
        component: PublicViewer,
        meta: {loadsData: true},
        beforeEnter(to) {
            return useWrapPromises(usePublishedSearchStore().fetchPublishedFolder(to.params.uuid))
                .catch(() => ({name: 'NotFound'}))
        },
        props: true,
    },
    {
        path: '/share/folder/:uuid/results/browse',
        name: 'Share.Folder.Browser',
        meta: {loadsData: true},
        component: PublicBrowser,
        beforeEnter(to) {
            return useWrapPromises(usePublishedSearchStore().fetchPublishedFolder(to.params.uuid))
                .catch(() => ({ name: 'NotFound' }))
        },
        props: true,
    },
    {
        path: '/share/analysis/:uuid',
        name: 'Share.Analysis',
        component: PublishedAnalysisViewerSelector,
        meta: { loadsData: true },
        beforeEnter: [VerifySharedAnalysisLink],
        props: true,
    },
    {
        // This is the dashboard share entry point.
        path: '/share/dashboard/:uuid',
        name: 'Share.Dashboard',
        meta: { requiresAuth: false },
        beforeEnter(to) {
            return useWrapPromises(

                // Make sure that we have field names
                useFieldsAndGroupsStore().fetchFields(),

                // Load the share details, and redirect to the dashboard, by setting the session in place.
                useWorkspacesStore().fetchActiveWorkspaceViaShare(to.params.uuid, 'dashboard'),

            ).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        // This is the actual dashboard share page.
        path: '/shared_dashboard/:analysis_id/:uuid',
        name: 'SharedAnalysisDashboard',
        component: SharedAnalysisDashboard,
        meta: { requiresAuth: false, loadsData: true },
        props: true,
        beforeEnter(to) {
            return useWrapPromises(

                // Load the "active" workspace (as a share)
                useWorkspacesStore().fetchActiveWorkspace(to.params.analysis_id, to.params.uuid),

            ).catch(()=> ({ name: 'NotFound' }))
        }
    },
    {
        path: '/share/chart/:uuid',
        name: 'Share.Chart',
        meta: { requiresAuth: false },
        beforeEnter(to) {
            return useWrapPromises(

                // Make sure that we have field names
                useFieldsAndGroupsStore().fetchFields(),

                // Load the share details, and redirect to the dashboard, by setting the session in place.
                useWorkspacesStore().fetchActiveWorkspaceViaShare(to.params.uuid, 'chart'),

            ).catch(() => ({ name: 'NotFound' }))
        }
    },
    {
        // This is the actual chart share page.
        path: '/shared_chart/:analysis_id/section/:analysis_section/:uuid',
        name: 'SharedAnalysisChart',
        component: SharedAnalysisChart,
        meta: { requiresAuth: false, loadsData: true },
        props: true,
        beforeEnter(to) {
            return useWrapPromises(

                // Load the "active" workspace (as a share)
                useWorkspacesStore().fetchActiveWorkspace(to.params.analysis_id, to.params.uuid),

            ).catch(()=> ({ name: 'NotFound' }))
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

/**
 * Authentication
 *
 * 1. If there is an authenticated user in the store, the route allows the page to load
 * 2. If there is no authenticated user, we will make a call to the API to check if there is a user
 *    that ties in with the session. If there is, the store will be populated with the details and page can load.
 * 3. If there is no valid session, the user is redirected to the login page.
 * 4. Redirect users attempting to reach the 'login' page to the dashboard of the application.
 * 5. If tracking page required, the logged user will be requested to choose client ID before navigating to dashboard of the application.
 */
router.beforeEach(async (to, from) => {
    const auth = useAuthStore()

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)

    const isGuestView = to.matched.some(record => record.meta.guest)

    const isEndUserRestricted = to.matched.some(record => record.meta.endUserRestricted)

    const isReadOnlyRestricted = to.matched.some(record => record.meta.readOnlyRestricted)

    const isOriginPlus = to.matched.some(record => record.meta.originPlus)

    // retrieve fresh user instance
    if (! auth.isAuthenticated) {
        try {
            await auth.getUser()
        } catch (e) {}
    }

    // Handle authentication redirects
    if (requiresAuth) {
        // Redirect unauthenticated users
        if (!auth.isAuthenticated) {
            return {
                name: 'Login',
                query: { redirect: to.fullPath } // Store the intended route
            }
        }

        // Redirect non-admin users from admin-only pages
        else if (requiresAdmin && !auth.isAdmin) {
            return { name: 'NotFound' }
        }

        // Redirect end users from end user-restricted pages
        else if (isEndUserRestricted && auth.isEndUser) {
            // redirect end users trying to access root url are redirected to search form instead of 404
            if (to.name === 'Dashboard') {
                return {name: 'Search'}
            }
            return {name: 'NotFound'}

        } else if (isReadOnlyRestricted && auth.isReadOnly) {
            // redirect read only users trying to access read only restricted pages
            return { name: 'NotFound' }

        // Redirect users without the Origin+ module from Origin+ pages
        } else if (isOriginPlus && !auth.moduleEnabled('origin_plus')) {
            return { name: 'NotFound' }
        }
    }


    // Redirect logged-in users from the login page to the dashboard
    else if (isGuestView && auth.isAuthenticated) {
        if (!auth.isEndUser) {
            return { name: 'Dashboard' }
        } else {
            return { name: 'Search' }
        }
    }

    // Force authenticated users with the tracking module required to choose a tracking reference if none is set
    if (auth.isAuthenticated && auth.trackingRequired) {
        const trackingStore = useActiveTrackingReferenceStore()

        if (! trackingStore.hasReference) {
            await trackingStore.fetchActiveTrackingReference()

            if (! trackingStore.hasReference) {
                useDynamicModalStore().open(TrackingModal, {}, false)
            }
        }
    }

    // If the requested route is loading in data, then show the NProgress loading indicator
    if (to.name !== from.name && to.matched.some(record => record.meta.loadsData)) {
        isLoading.value = true
    }
})


router.afterEach((to, from) => {
    // Make sure that the NProgress loading indicator disappears before DOM updates are triggered.
    isLoading.value = false
})

export default router;
